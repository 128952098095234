import React from 'react';
import { Link } from 'gatsby';
import PageLinks from './page-links'
import Icon from './icon'

export default class FooterPageLinks extends PageLinks {

  pageLink(title, path, attributes) {
    return (
      <Link to={path} {...attributes}>
        <span>{title}</span>
        <Icon name="chevron-right" />
      </Link>
    )
  }

  render() {
    return (
      <div className="sitemap">
        <h3>Pagina's</h3>
        <ul className="page-list"> 
          <li key="frontpage">
            <Link to="/" activeClassName="active">
              <span>Homepage</span>
              <Icon name="chevron-right" />
            </Link>
          </li>
          {this.pages()}
        </ul>
      </div>
    )
  }

}


