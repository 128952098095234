import React, { Component } from "react";
import axios from "axios";
import classNames from "classnames";
import Alert from "react-s-alert";

// Assets.
import FormStyles from "../components/css-modules/form-mail.module.scss";

class MailForm extends Component {
  constructor(props) {
    super(props);

    this.url = `https://mailthis.to`;
    this.alias = `daans-administratie`;
    this.defaulState = {
      name: "",
      email: "",
      phone: "",
      message: ""
    };

    this.state = {
      ...this.defaulState
    };
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const data = {
      _subject: `Contact form Daans Administratie`,
      _replyto: this.alias,
      _honeypot: ``,
      _after: this.props[`after`] !== undefined ? this.props.after : ``,
      ...this.state
    };

    const message = `
            <h3>U wordt zo doorgestuurd naar een andere site om uw mailinzending te bevestigen</h3>
            <p>Hierna wordt u automatisch teruggestuurd naar deze website.</p>`;

    Alert.warning(message, {
      position: "top-right",
      effect: "stackslide",
      preserveContext: true
    });

    axios
      .post(`${this.url}/${this.alias}`, data)
      .then(result => {
        if (typeof window !== "undefined") {
          window.location.href = `https://mailthis.to/confirm`;
        }
      })
      .catch(error => {
        Alert.error(
          `<h3>Er ging iets mis bij het versturen, probeer het nogmaals of stuur direct een e-mail naar simon@daans.nu</h3>`,
          {
            position: "top-right",
            effect: "stackslide",
            preserveContext: true
          }
        );
      });
  };

  render() {
    const { name, email, phone, message } = this.state;

    return (
      <form onSubmit={this.onSubmit} className={FormStyles.form}>
        <div className={FormStyles.fields}>
          <div className={FormStyles.field}>
            <label htmlFor="name">Naam</label>
            <input
              type="text"
              name="name"
              placeholder="Naam"
              value={name}
              onChange={this.onChange}
              required
            />
          </div>

          <div className={FormStyles.field}>
            <label htmlFor="email">E-mailadres</label>
            <input
              type="email"
              name="email"
              placeholder="E-mailadres"
              value={email}
              onChange={this.onChange}
              required
            />
          </div>

          <div className={FormStyles.field}>
            <label htmlFor="phone">Telefoonnummer</label>
            <input
              type="tel"
              name="phone"
              placeholder="Telefoonnummer"
              value={phone}
              onChange={this.onChange}
            />
          </div>

          <div
            className={classNames(
              FormStyles.field,
              FormStyles.fieldTypeTextarea
            )}
          >
            <label htmlFor="message">Bericht</label>
            <textarea
              name="message"
              placeholder="Wat kan ik voor u betekenen?"
              value={message}
              onChange={this.onChange}
              required
            />
          </div>
        </div>

        <div className={FormStyles.actions}>
          <button
            type="submit"
            className={classNames(`button-primary`, FormStyles.submit)}
          >
            Verzenden
          </button>
        </div>
      </form>
    );
  }
}

export default MailForm;
