import React from "react";
import PageLinks from "./page-links";
import logo from "../images/daans-administratie-logo.svg";
import Link from "gatsby-link";

const Nav = () => (
  <div className="navigation">
    <Link to="/" className="logo">
      <img src={logo} alt="Daans Administratie" width="380" />
    </Link>

    <nav className="main-navigation">
      <PageLinks cta="/contact" />
    </nav>
  </div>
);

export default Nav;
