import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { snakeCase } from "lodash/string";

export default class PageLinks extends React.Component {
  pages() {
    var cta = this.props.cta !== undefined ? this.props.cta : "";
    return (
      <StaticQuery
        query={graphql`
          query PageLinkQuery {
            indexPages: allJavascriptFrontmatter(
              filter: { frontmatter: { weight: { gt: 0 } } }
            ) {
              edges {
                node {
                  frontmatter {
                    path
                    title
                    weight
                  }
                }
              }
            }
            mdxPages: allMdx(filter: { frontmatter: { weight: { gt: 0 } } }) {
              edges {
                node {
                  id
                  fileAbsolutePath
                  frontmatter {
                    path
                    title
                    weight
                  }
                  parent {
                    ... on File {
                      name
                      sourceInstanceName
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          // // Merge the results into on dataset.
          const { indexPages, mdxPages } = data;
          const pages = [...indexPages.edges, ...mdxPages.edges];

          // Sort by page weight.
          pages.sort(({ node: a }, { node: b }) => {
            const { frontmatter: frontmatterA } = a;
            const { frontmatter: frontmatterB } = b;
            return frontmatterA.weight - frontmatterB.weight;
          });

          return pages.map(({ node: page }) => {
            const { frontmatter } = page;
            const uKey =
              page.id !== undefined
                ? page.id
                : `${frontmatter.weight}-${snakeCase(frontmatter.title)}`;

            let opts = {
              activeClassName: "active",
              className: frontmatter.path.indexOf(cta) !== -1 ? "button" : ""
            };

            if (frontmatter.short_title !== null) {
              const attr = { "data-mobile-title": frontmatter.short_title };
              opts = { ...opts, ...attr };
            }

            return (
              <li key={uKey}>
                {this.pageLink(frontmatter.title, frontmatter.path, opts)}
              </li>
            );
          });
        }}
      />
    );
  }

  pageLink(title, path, attributes) {
    return (
      <Link to={path} {...attributes}>
        <span>{title}</span>
      </Link>
    );
  }

  render() {
    return <ul className="page-list">{this.pages()}</ul>;
  }
}
