import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import classNames from "classnames";
import ScrollAnimation from "react-animate-on-scroll";

// Components.
import Layout from "../components/layout";
import Testimonial from "../components/testimonial";
// Assets.
import "../components/sass/animations.scss";
import Simon from "../images/simon.png";
import HeadingStyles from "../components/css-modules/heading.module.scss";
import BlockStyles from "../components/css-modules/blocks.module.scss";
import IconAirplane from "../images/icon-airplane.svg";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Medeplichtig aan jouw success</title>
      </Helmet>

      <header className={HeadingStyles.heading}>
        <div className={HeadingStyles.payoff}>
          <p className={HeadingStyles.intro}>
            Mijn naam is Daans. Simon Daans.
          </p>
          <h1 className={HeadingStyles.headingTitle}>
            Ik ondersteun jou als ondernemer bij het besturen en beheersen van
            je organisatie.
          </h1>

          <p>
            Van het bewaken van de financiële bedrijfsresultaten, tot het
            digitaliseren van administratieve processen, tot projectmanagement:
            je kunt het met een gerust hart aan mij toevertrouwen.
          </p>
          <p>
            Als (sparrings)partner in crime help ik jou je business efficiënter
            en effectiever in te richten, met als resultaat: professionalisering
            en/of groei. Samen tillen we jouw bedrijf naar een hoger plan. Ik
            kom je graag een X aantal uur per week versterken.
          </p>
        </div>

        <figure
          className={classNames(HeadingStyles.image, HeadingStyles.portrait)}
        >
          <img src={Simon} alt="Simon Daans" />
          <figcaption>Simon Daans</figcaption>
        </figure>
      </header>

      <div className={BlockStyles.grid}>
        <Link
          to="/contact"
          className={classNames(BlockStyles.frontpage, BlockStyles.contact)}
        >
          <span>Contact</span>
          <h3>Laat een berichtje achter</h3>

          <ScrollAnimation
            animateIn="flyInAirplane"
            animateOut="flyOutAirplane"
            initiallyVisible={true}
            duration={parseInt(2)}
          >
            <img
              src={IconAirplane}
              alt="Airplane icon"
              width="100"
              className={BlockStyles.airplane}
            />
          </ScrollAnimation>
        </Link>

        <Link
          to="/contact"
          className={classNames(BlockStyles.frontpage, BlockStyles.work)}
        >
          <span>Werkwijze</span>
          <h3>Ontdek wat we voor jou kunnen betekenen</h3>
        </Link>
      </div>

      <div className="footer-top">
        <div className="footer-top-inner">
          <Testimonial items={data.testimonials} />

          <div className="about-daans-wrapper">
            <div className="about-daans">
              <p>Weten waar Daans voor staat?</p>
              <Link
                to="/over-daans"
                className="button-primary m--block"
                style={{
                  display: "inline-block"
                }}
              >
                Over Daans
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const frontmatter = {
  title: `Homepage`,
  path: `/`,
  description: ``,
  weight: 0
};

export const Testimonials = graphql`
  query TestimonialQuery {
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "testimonial" } } }
      sort: { fields: [frontmatter___weight], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            type
            weight
            logo {
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
            name
            company
          }
          html
        }
      }
    }
  }
`;

export default IndexPage;
