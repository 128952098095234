import React from "react";
import Helmet from "react-helmet";
import classNames from "classnames";
import { graphql } from "gatsby";

// Components.
import Layout from "../components/layout";
import Img from "gatsby-image";
// Assets.
import ContentStyles from "../components/css-modules/content.module.scss";

const OverDaans = ({ data }) => {
  // Fetch images.
  const { image1, image2, image3 } = data;

  return (
    <Layout>
      <Helmet>
        <title>Over Daans</title>
      </Helmet>

      <header
        className={classNames(
          ContentStyles.imageHeader,
          ContentStyles.spacingBelow
        )}
      >
        <div className={ContentStyles.imageHeaderWrapper}>
          <h1 className={ContentStyles.imageHeaderSubtitle}>Over Daans</h1>
          <p className={ContentStyles.imageHeaderHeading}>
            Ik ben Simon Daans en ik ga ervoor zorgen dat jij je weer kunt
            focussen op hetgeen waarvoor je ooit ondernemer werd.
          </p>
        </div>

        <Img
          fluid={image1.childImageSharp.fluid}
          className={ContentStyles.imageHeaderImage}
        />
      </header>

      <div
        className={classNames(
          ContentStyles.imageGrid,
          ContentStyles.imageLeft,
          ContentStyles.spacingBelow
        )}
      >
        <Img fluid={image2.childImageSharp.fluid} />

        <div className={ContentStyles.imageGridContent}>
          <h2 className={ContentStyles.heading2}>Ik ben:</h2>
          <ul className="item-list">
            <li>
              De specialist die je helpt bij het automatiseren van digitale
              processen.
            </li>
            <li>
              De controller die ervoor zorgt dat jouw doelen en missies altijd
              voorop staan.
            </li>
            <li>
              De man die de financiële positie van je bedrijf bewaakt met zijn
              leven ;)
            </li>
            <li>De projectmanager die elk project in goede banen leidt.</li>
            <li>
              De frisse wind: je administratie altijd up to date en inzicht in
              je resultaten.
            </li>
            <li>
              Het klankbord dat jou voorziet van de juiste adviezen en
              (ongevraagde) tips.
            </li>
          </ul>
        </div>
      </div>

      <div
        className={classNames(
          ContentStyles.imageGrid,
          ContentStyles.imageRight,
          ContentStyles.spacingBelow
        )}
      >
        <div className={ContentStyles.imageGridContent}>
          <h2 className={ContentStyles.heading2}>Kortom</h2>
          <p>
            Zie mij gerust als het geheime wapen voor slimme ondernemers. Door
            de jaren heen heb ik al tal van opdrachtgevers in verschillende
            branches ontzorgd. Met mijn brede en oplossingsgerichte blik kan ik
            ook jouw bedrijf de ondersteuning bieden die het nodig heeft.
          </p>
        </div>

        <Img fluid={image3.childImageSharp.fluid} />
      </div>
    </Layout>
  );
};

export const frontmatter = {
  title: `Over Daans`,
  path: `/over-daans`,
  description: ``,
  weight: 10
};

export default OverDaans;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 800, quality: 85) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
        presentationWidth
      }
    }
  }
`;

export const query = graphql`
  query {
    image1: file(
      relativePath: { eq: "foto-daans-bureau.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      ...fluidImage
    }
    image2: file(
      relativePath: { eq: "bureau.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      ...fluidImage
    }
    image3: file(
      relativePath: { eq: "shake-on-it.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      ...fluidImage
    }
  }
`;
