import React from "react";
import FooterPageLinks from "./footer-page-links";
import Icon from "./icon";

// Assets.
import logo from "../images/daans-administratie-logo.svg";

const Footer = () => (
  <footer
    className="footer"
    style={{
      background: "#eeebe6",
      padding: "1em"
    }}
  >
    <div className="grid">
      <div className="footer-summary">
        <img src={logo} width="250" alt="" />
        <p>
          Ik ben Simon Daans en ik ga ervoor zorgen dat jij je weer kunt
          focussen op hetgeen waarvoor je ooit ondernemer werd. Wil je mijn
          partners in crime zien? Kijk op mijn{" "}
          <a href="https://www.linkedin.com/in/simon-daans-217842108/">
            LinkedIn profiel
          </a>
          .
        </p>
        <a
          href="https://www.linkedin.com/in/simon-daans-217842108/"
          className="social linkedin"
        >
          <Icon
            name="linkedin"
            title="Bekijk Daans Administratie op LinkedIn"
          />
        </a>
      </div>

      <FooterPageLinks frontpage="true" />

      <div className="footer-contact">
        <h3>Contact</h3>
        <p>
          Daans Administratie en advies
          <br />
          Remuslaan 17
          <br />
          5631 JN Eindhoven
          <br />
          <a href="tel:0031614377159" className="phone">
            +31 (0)6 14 37 71 59
          </a>
        </p>
        <p>
          <a href="mailto:simon@daans.nu">simon@daans.nu</a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
