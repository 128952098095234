import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import Nav from "./nav";
import Footer from "./footer";
import "./sass/layout.scss";
import "./sass/elements.scss";
import "./sass/content.scss";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
            description
          }
        }
      }
    `}
    render={data => {
      const { title, siteUrl } = data.site.siteMetadata;

      return (
        <>
          <Helmet
            defaultTitle="{title}"
            titleTemplate={`%s | ${title}`}
            meta={[{ name: "description", content: "Sample" }]}
          >
            <html lang="nl" />
            <title>{title}</title>

            <meta property="og:url" content={siteUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="nl" />
            <meta property="og:site_name" content={title} />
            {/* <meta property="og:image" content={`${siteUrl}${gatsbyIcon}`} /> */}
            {/* <meta property="og:image:width" content="512" /> */}
            {/* <meta property="og:image:height" content="512" /> */}
          </Helmet>

          <Nav />
          <main>{children}</main>
          <Footer />
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
