import React from "react";
import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <h1>Pagina niet gevonden</h1>
    <p>Sorry deze pagina bestaat niet.</p>
  </Layout>
);

export const frontmatter = {
  title: `Pagina niet gevonden`,
  path: `/404`,
  description: ``
};

export default NotFoundPage;
