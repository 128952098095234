// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/builds/simondaans/daans-administratie/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-8-e-9-aad-3-f-213-fd-96-f-29-ad-4-cdba-5-d-3176-b-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/builds/simondaans/daans-administratie/.cache/gatsby-mdx/mdx-wrappers-dir/8e9aad3f213fd96f29ad4cdba5d3176b--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-8-e-9-aad-3-f-213-fd-96-f-29-ad-4-cdba-5-d-3176-b-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---src-pages-index-js": () => import("/builds/simondaans/daans-administratie/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/builds/simondaans/daans-administratie/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/builds/simondaans/daans-administratie/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-over-daans-js": () => import("/builds/simondaans/daans-administratie/src/pages/over-daans.js" /* webpackChunkName: "component---src-pages-over-daans-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/simondaans/daans-administratie/.cache/data.json")

