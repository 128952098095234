import React from 'react'
import { withPrefix } from 'gatsby'

const Icon = (props) => (
  <svg className={`icon icon-${props.name}`}>
    {"title" in props && props.title && 
      <title>{ props.name }</title>
    }
    <use xlinkHref={withPrefix(`symbols.svg#${props.name}`)} />
  </svg>
)

export default Icon