module.exports = [{
      plugin: require('/builds/simondaans/daans-administratie/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/simondaans/daans-administratie/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138218920-1"},
    },{
      plugin: require('/builds/simondaans/daans-administratie/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
