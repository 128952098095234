import React from "react";
import { graphql } from "gatsby";
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import { withMDXScope } from "gatsby-mdx/context";
// import ImageText from '../components/image-text'

function MdxPageTemplate({ data: { mdx }, scope }) {
  return (
    <Layout>
      <Helmet>
        <title>{mdx.frontmatter.title}</title>
      </Helmet>

      {/* <MDXRenderer scope={{ ImageText, ...scope }} >{mdx.code.body}</MDXRenderer> */}
      <article>
        <h1>{mdx.frontmatter.title}</h1>
        <MDXRenderer scope={{ ...scope }}>{mdx.code.body}</MDXRenderer>
      </article>
    </Layout>
  );
}

export default withMDXScope(MdxPageTemplate);

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
      }
      code {
        body
      }
    }
  }
`;
